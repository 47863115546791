$(function() {
    $('[data-button="toggle-menu"]').click(function(e) {
        e.preventDefault();

        $('#mobile-left-menu').toggleClass('opened');
    })

    $('[data-button="share-sns"]').click(function(e) {
        e.preventDefault();
        console.log(1);

        var code = $(this).attr('data-type')
        var snsimages = $(this).attr('data-image')
        var snsTitle =  $(this).attr('data-title')
        var url = $(this).attr('data-url')

        if(! url || !code || !snsTitle)
            return;

        if(code === "kakaotalk") {
            Kakao.init('3bec2345a029972a68f8279879478ba7');

            Kakao.Link.createDefaultButton({
                container: '#npsShKakaoTalk',
                objectType: 'feed',
                content: {
                    title: snsTitle,
                    description: '출처 : 국민연금 온에어',
                    imageUrl: snsimages,
                    imageWidth: 620,
                    imageHeight: 350,
                    link: {
                        mobileWebUrl: document.URL,
                        webUrl: document.URL
                    }
                },
                buttons: [
                    {
                        title: '콘텐츠 보기',
                        link: {
                            mobileWebUrl: document.URL,
                            webUrl: document.URL
                        }
                    }
                ]
            });
            return;
        }
        else if (code === 'url') {
            var $div = $('<input>').attr('data-temp','clipboard')
            $('body').append($div);
            $div.val(url).select();

            $('#textbox-url').select();
            try {
                var successful = document.execCommand('copy');
                alert('클립보드에 주소가 복사되었습니다. Ctrl + V 로 붙여넣기 하세요.');
                $div.remove();
            } catch (err) {
                alert('이 브라우저는 지원하지 않습니다.');
                $div.remove();
            }
            return;
        }

        switch(code) {
            case "twitter":
                //트위터
                url = '//twitter.com/intent/tweet?text='+encodeURIComponent(snsTitle)+'&url='+encodeURIComponent(url);
                break;

            case "telegram":
                //텔레그램
                url = 'https://telegram.me/share/url?url='+encodeURIComponent(url);
                break;

            case "facebook":
                //페이스북
                url = '//www.facebook.com/sharer/sharer.php?u='+encodeURIComponent(url)+'&t='+snsTitle;
                break;

            case "kakaostory":
                //카카오스토리
                url = 'https://story.kakao.com/share?url='+encodeURIComponent(url);
                break;

            case "naverblog":
                //네이버
                url = 'http://share.naver.com/web/shareView.nhn?url='+encodeURIComponent(url)+'&title='+encodeURIComponent(snsTitle);
                break;

            case "naverband":
                //밴드
                url = 'http://www.band.us/plugin/share?body='+encodeURIComponent(snsTitle)+'%0A'+encodeURIComponent(url);
                break;

            case "google":
                //구글
                url = '//plus.google.com/share?url='+url;
                break;

            case "pinterest":
                //pinterest
                url = '//www.pinterest.com/pin/create/button/?url='+url+'&media='+snsimages+'&description='+encodeURIComponent(snsTitle);
                break;
        }

        window.open(url,'','width=600,height=300,top=100,left=100,scrollbars=yes');

    })
})
